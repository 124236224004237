<template>
  <div class="brig">
    <v-card tile>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Бригада</th>
              <th class="text-left">Район</th>
              <th class="text-left">Монтажники</th>
              <th class="text-right">Действия</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(brig, index_id) in brigs" :key="index_id">
              <td>{{ brig.displayName }}</td>
              <td>{{ brig.location  }}</td>
              <td style="min-width: 130px;">
                <span v-if="brig.users && brig.users.length > 0">
                  <span v-for="(user, index_user) in brig.users" :key="index_user">
                    {{ user.displayName }}<!--
                    --><span v-if="index_user + 1 < brig.users.length">, </span>
                  </span>
                </span>
                <span v-else>Не назначены</span>
              </td>
              <td style="min-width: 110px;" class="text-right">
                <v-btn @click="editBrig(brig)" icon color="#424242">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="deleteBrig(brig)" icon color="#bf4037">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="createBrig()">
          <v-icon>mdi-account-multiple-plus</v-icon>
          <span class="pl-2">Добавить бригаду</span>
        </v-btn>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import Brig from  '@/components/Modals/Brig/Index'
import Confirm from  '@/components/Modals/Confirm'
import { RoleText } from '@/helpers'

export default {
  name: 'Usres',
  components: {},
  data: () => ({
    roleText: RoleText,
    error: null
  }),
  async created() {
    if (this.brigs.length === 0) {
      this.$store.dispatch('brigs/loadBrigs')
    }
    if (this.users.length === 0) {
      this.$store.dispatch('users/loadUsers')
    }
  },
  computed: {
    brigs() {
      return this.$store.getters['brigs/getBrigs']
    },
    users() {
      return this.$store.getters['users/getUsers']
    }
  },
  methods: {
    async editBrig(brig) {
      const editBrig = await this.$showModal({
        component: Brig,
        isPersistent: true,
        props: {
          edit: true,
          data: brig
        }
      })
      if (editBrig) {
        this.$store.dispatch('brigs/updateBrig', {
          id: brig.id,
          data: editBrig
        })
      }
    },
    async createBrig() {
      const createBrig = await this.$showModal({
        component: Brig,
        isPersistent: true
      })

      if (createBrig) {
        this.$store.dispatch('brigs/createBrig', createBrig)
      }
    },
    async deleteBrig(brig) {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление бригады`,
          text: `Действительно хотите удалить бригаду "${brig.displayName}"?`
        }
      })

      if (res) {
        this.$store.dispatch('brigs/deleteBrig', brig)
      }
    }
  }
}
</script>
